import React from 'react';
import { Animation } from '../../components/Animation';
import { Section } from '../../components/Section';
import { Slider } from '../../components/Slider';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { useLocalDataSource } from './data';
import { PageSection } from '../../types';
import * as classes from './style.module.css';
import { CertificationCard, CertificationCardSkeleton } from '../../components/CertificationCard';

function getDate(date: string): Date {
    const parts = date.split('-');
    return new Date(Number.parseInt(parts[0]), Number.parseInt(parts[1]) - 1, Number.parseInt(parts[2]));
}

export function CertificationsSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const [certifications, setCertifications] = React.useState<CertificationCard[]>([]);

    async function collectCertificationsFromSources(): Promise<CertificationCard[]> {
        const certificationsList: CertificationCard[] = [];

        const sectionCertifications = response.allCertificationsJson.certificates;
        if (sectionCertifications.length > 0) {
            sectionCertifications.forEach((certification) => {
                certificationsList.push({
                    title: certification.title,
                    school: certification.school,
                    certDate: getDate(certification.certDate),
                    id: certification.id,
                    image: certification.image,
                    description: certification.description,
                    // link: certification.slug,
                });
            });
        }

        return certificationsList.slice().sort((a, b) => b.certDate.getTime() - a.certDate.getTime());
    }

    React.useEffect(() => {
        (async function () {
            setCertifications(await collectCertificationsFromSources());
        })();
    }, []);

    return (
        <Animation type="fadeUp" delay={500}>
            <Section
                anchor={props.sectionId}
                heading={props.heading}
                additionalClasses={[classes.CertificationsContainer]}
            >
                <Slider additionalClasses={[classes.Certifications]}>
                    {certifications.length > 0
                        ? certifications.map((certification, key) => {
                              return (
                                  <CertificationCard
                                      key={key}
                                      data={certification}
                                  />
                              );
                          })
                        : [...Array(3)].map((_, key) => {
                              return <CertificationCardSkeleton key={key} />;
                          })}
                </Slider>
            </Section>
        </Animation>
    );
}
