import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface CertificationPreviewQueryResult {
    allCertificationsJson: {
        certificates: {
            certDate: string;
            id: string;
            image: {
                src: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                } | null;
                alt: string | null;
            };
            school: string;
            title: string;
            description: string;
        }[];
    };
}

export const useLocalDataSource = (): CertificationPreviewQueryResult => {
    return useStaticQuery(graphql`
        query CertificationPreviewQuery {
            allCertificationsJson {
                certificates: nodes {
                    certDate
                    id
                    image {
                        src {
                            childImageSharp {
                                gatsbyImageData(placeholder: TRACED_SVG)
                            }
                        }
                        alt
                    }
                    school
                    title
                    description
                }
            }
        }
    `);
};
