import React, { useState } from 'react';
// import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { ImageObject } from '../../types';
import { Theme, useGlobalState } from '../../context';
import * as classes from './style.module.css';
import Modal from '../certModal';

export interface CertificationCard {
    image?: ImageObject;
    title: string;
    school: string;
    certDate: Date;
    id: string;
    description: string;
    // link: string;
}

interface CertificationCardProps {
    data: CertificationCard;
}

function trancWord (str:string, max:number){
        if (str.length <= max) { return str; }
        let subString = str.substr(0, max);
        return (str ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
}

export function CertificationCard(props: CertificationCardProps): React.ReactElement {
    const { globalState } = useGlobalState();
    // const darkModeEnabled = globalState.theme === Theme.Dark;

    const [modalState, setModalState] = useState(false);

    const certificationCard = (
        <div>
            <article
                className={classes.Card}
                onClick={() => setModalState(!modalState)}
            >
                {props.data.image && (
                    <div>
                        {props.data.image && props.data.image.src && (
                            <GatsbyImage
                                image={
                                    props.data.image.src.childImageSharp
                                        .gatsbyImageData
                                }
                                alt={props.data.image.alt || props.data.title}
                            />
                        )}
                    </div>
                )}
                <div className={classes.DescriptionWrapper}>
                    <h4 className={classes.Title}>
                        {trancWord(props.data.title, 30)}
                    </h4>
                    <div className={classes.Details}>
                        {props.data.school && <span>{props.data.school}</span>}
                        {props.data.certDate && (
                            <span className={classes.ReadingTime}>
                                {formatDate(props.data.certDate)}
                            </span>
                        )}
                    </div>
                </div>
            </article>
            <Modal
                estado={modalState}
                cambiarEstado={setModalState}
                titulo={props.data.title}
                mostrarHeader={true}
                imagen={
                    <GatsbyImage
                        className={classes.ModalImageWrapper}
                        image={
                            props.data.image.src.childImageSharp.gatsbyImageData
                        }
                        alt={props.data.image.alt || props.data.title}
                    />
                }
                mostrarOverlay={true}
                posicionModal={"center"}
                padding={"30px"}
            >
                <div className={classes.Modal}>
                    <h1>{props.data.title}</h1>
                    <p className={classes.Details}>
                        <span>{props.data.school}</span>
                        <span className={classes.ReadingTime}>
                            {formatDate(props.data.certDate)}
                        </span>
                    </p>
                    <p className={classes.CertificationInfo}>{props.data.description}</p>
                </div>
            </Modal>
        </div>
    );

    return certificationCard;
}

export function CertificationCardSkeleton(): React.ReactElement {
    const { globalState } = useGlobalState();
    const darkModeEnabled = globalState.theme === Theme.Dark;
    return (
        <div
            className={classes.Card}
            style={darkModeEnabled ? { border: '0.125rem solid var(--primary-color)' } : undefined}
        >
            <div className={classes.DescriptionWrapper}>
                <SkeletonLoader
                    style={{
                        height: '1.5rem',
                        marginBottom: '.5rem',
                        background: 'var(--tertiary-color)',
                    }}
                />
                <SkeletonLoader style={{ height: '4rem', background: 'var(--tertiary-color)' }} />
                <SkeletonLoader
                    style={{
                        height: '.75rem',
                        width: '50%',
                        marginTop: '.5rem',
                        background: 'var(--tertiary-color)',
                    }}
                />
            </div>
        </div>
    );
}

function formatDate(date: Date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}
