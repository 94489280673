import React from "react";
// import {
//     AboutSection,
//     ArticlesSection,
//     ContactSection,
//     HeroSection,
//     InterestsSection,
//     Page,
//     ProjectsSection,
//     Seo,
//     CertificationsSection
// } from 'gatsby-theme-portfolio-minimal';

import { AboutSection } from "../sections/About";
import { ArticlesSection } from "../sections/Articles";
import { ContactSection } from "../sections/Contact";
import { HeroSection } from "../sections/Hero";
import { InterestsSection } from "../sections/Interests";
import { ProjectsSection } from "../sections/Projects";
import { CertificationsSection } from "../sections/Certifications";
import { Page } from "../components/Page";
import { Seo } from "../components/Seo";

// Will useSplashScreenAnimation only if its the first time that the person visits the page
// function useSplash() {
//     var out = '';
//     return out;
// }

export default function IndexPage() {
    return (
        <>
            <Seo title="Christian Leyva" />
            <Page>
                <HeroSection sectionId="hero" />
                <AboutSection sectionId="about" heading="About me" />
                <InterestsSection sectionId="details" heading="Skills" />
                <ProjectsSection
                    sectionId="features"
                    heading="Featured Projects"
                />
                <ArticlesSection
                    sectionId="articles"
                    heading="Latest Articles"
                    sources={["Blog"]}
                />
                <CertificationsSection
                    sectionId="certifications"
                    heading="Certifications"
                />
                <ContactSection sectionId="contact" heading="Contact" />
            </Page>
        </>
    );
}
