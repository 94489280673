
import styled from 'styled-components';
import React from 'react';

const Modal = ({
    children,
    estado,
    imagen,
    cambiarEstado,
    titulo,
    mostrarHeader,
    mostrarOverlay,
    posicionModal,
    padding,
}) => {
    return (
        <>
            {estado && (
                <Overlay mostrarOverlay={mostrarOverlay} posicionModal={posicionModal}>
                    <ContenedorModal padding={padding}>
                        {mostrarHeader && <EncabezadoModal>{imagen}</EncabezadoModal>}

                        <BotonCerrar onClick={() => cambiarEstado(false)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                // class="bi bi-x"
                                viewBox="0 0 512 512"
                            >
                                <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                            </svg>
                        </BotonCerrar>

                        {children}
                    </ContenedorModal>
                </Overlay>
            )}
        </>
    );
};

export default Modal;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: ${(props) => (props.mostrarOverlay ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,0)')};
    padding: 40px;
    display: flex;
    align-items: ${(props) => (props.posicionModal ? props.posicionModal : 'center')};
    justify-content: center;
`;

const ContenedorModal = styled.div`
    width: 500px;
    min-height: 100px;
    background: var(--background-color);
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: ${(props) => (props.padding ? props.padding : "20px")};
    white-space: pre-line;
    
`;

const EncabezadoModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;

    h3 {
        font-weight: 500;
        font-size: 16px;
        color: #1766dc;
    }
`;

const BotonCerrar = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    cursor: pointer;
    transition: 0.3s ease all;
    border-radius: 10px;
    color: var(--primary-color);

    &:hover {
        background: var(--box-shadow-color);
    }

    svg {
        width: 100%;
        height: 100%;
    }
`;
